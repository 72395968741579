// state/formState.js
import { atom } from 'recoil';

export const certificationState = atom({
  key: 'step2', // identificatore unico
  default: {
    "EMAS Eco-Management Audit Scheme": { checked: false, file: null, expiryDate: null },
    "UNI EN ISO 14040 Gestione ambientale -Valutazione del ciclo di vita - Principi e quadro di riferimento": { checked: false, file: null, expiryDate: null },
    "UNI EN ISO 14044 Gestione ambientale-Valutazione del ciclo di vita - Requisiti e linee guida": { checked: false, file: null, expiryDate: null },
    "Cradle_to_Cradle": { checked: false, file: null, expiryDate: null },
    "UNI EN ISO 41001 Facility management - Sistemi di gestione - Requisiti con guida per l'utilizzo": { checked: false, file: null, expiryDate: null },
    "VinylPlus® Product Label Certified label for sustainable development (for PVC products in construction)- Standard Criteria Document June 28 2017- updated version 1": { checked: false, file: null, expiryDate: null },
    "FOS 0001 Friend of the sea": { checked: false, file: null, expiryDate: null },
    "UNI CEI EN ISO 50001 Sistemi di gestione dell'energia - requisiti e linee guida per l'uso": { checked: false, file: null, expiryDate: null },
    "UNI EN ISO 14001 Sistemi di gestione ambientale - Requisiti": { checked: false, file: null, expiryDate: null },
    "UNI ISO 20121 Sistemi di gestione sostenibile degli eventi - Requisiti e guida per l'utilizzo": { checked: false, file: null, expiryDate: null },
    "Programme for the Endorsement of Forest Certification": { checked: false, file: null, expiryDate: null }, // Programme for the Endorsement of Forest Certification
    "CCS Content Claim Standard": { checked: false, file: null, expiryDate: null }, // Content Claim Standard
    "GOCH GOTS Chemical Products": { checked: false, file: null, expiryDate: null },
    "GOTS Global Organic Textile Standard": { checked: false, file: null, expiryDate: null }, // Global Organic Textile Standard
    "GRCH GRS Chemical Products": { checked: false, file: null, expiryDate: null },
    "GRS Global Recycled Standard": { checked: false, file: null, expiryDate: null }, // Global Recycled Standard
    "NPF Nativa Precious Fiber": { checked: false, file: null, expiryDate: null }, // Nativa Precious Fiber
    "OCS Organic Content Standard": { checked: false, file: null, expiryDate: null }, // Organic Content Standard
    "RCS Recycled Claim Standard": { checked: false, file: null, expiryDate: null }, // Recycled Claim Standard
    "RDS Responsible Down Standard": { checked: false, file: null, expiryDate: null }, // Responsible Down Standard
    "RMS Responsible Mohair Standard": { checked: false, file: null, expiryDate: null }, // Responsible Mohair Standard
    "RWS Responsible Animal Fiber Standard": { checked: false, file: null, expiryDate: null }, // Responsible Wool Standard
    "SFA Sustainable Fiber Alliance": { checked: false, file: null, expiryDate: null }, // Sustainable Fiber Alliance
    "Blauer Angel (The German Ecolabel)": { checked: false, file: null, expiryDate: null }, // The German Ecolabel
    "Dichiarazione ambientale di prodotto (DAP) Environmental Declaration Product (EDP) I livello ECOLABEL ISO 14024": { checked: false, file: null, expiryDate: null }, // Dichiarazione ambientale di prodotto - Environmental Declaration Product
    "REMADE IN ITALY (circular economy)": { checked: false, file: null, expiryDate: null },
    "PSV Plastica Seconda Vita": { checked: false, file: null, expiryDate: null }, // Plastica Seconda Vita
    "Operatori biologici controllati": { checked: false, file: null, expiryDate: null },
  },
});

export const additionalCertificationState = atom({
  key: 'additionalCertification', // identificatore unico
  default: []
});

