import React, { useState } from 'react';
import { Typography, Button,TextField,FormControlLabel,Checkbox,  Box, Grid, Card, CardContent, Stepper, Step, StepLabel } from '@mui/material';
import Step0 from './FormStep0'; 
import Step1 from './FormStep1'; 
import Step2 from './FormStep2'; 
import StepLegale from './FormStepLegale'; 
import Step3 from './FormStep3'; 
import Step4 from './FormStep4'; 
import Step5 from './FormStep5'; 
import { pageState } from './state/pageState';
import { useRecoilState } from 'recoil';
import { useRecoilValue } from 'recoil';

import { useSetRecoilState } from 'recoil';
import { formState } from './state/step0';
import { referentiState } from './state/step1';
import { legaleState } from './state/stepLegale';
import { certificationState } from './state/step2';
import { additionalCertificationState } from './state/step2';
import { socialCertificationState } from './state/step4';
import { additionalSocialCertificationState } from './state/step4';


function FormComponent() {

const [submitStatus, setSubmitStatus] = useState('idle'); // 'idle', 'loading', 'success', 'error'
const [errorMessage, setErrorMessage] = useState('');
const [codicePratica, setCodicePratica] = useState('');
const [acceptTerms, setAcceptTerms] = useState(false);
const [acceptPrivacy, setAcceptPrivacy] = useState(false);


const [step, setStep] = useRecoilState(pageState);
const steps = ['Dati Azienda', 'Dati Referente', 'Dati Legale Rappresentante', 'Sostenibilità Ambientale',  'Altre Certificazioni Ambientali', 'Sostenibilità Sociale',  'Altre Certificazioni Sociale', 'Invio'];


const formData = useRecoilValue(formState);

const {
    nomeAzienda,
    partitaIva,
    email,

} = formData;
const [datiAzienda] = useRecoilState(formState);
const [datiReferente] = useRecoilState(referentiState);
const [datiLegale] = useRecoilState(legaleState);
const [certifications] = useRecoilState(certificationState);
const [additionalCertifications] = useRecoilState(additionalCertificationState);
const [socCertifications] = useRecoilState(socialCertificationState);
const [additionalSocCertifications] = useRecoilState(additionalSocialCertificationState);


const setFormData = useSetRecoilState(formState);
const setReferenteData = useSetRecoilState(referentiState);
const setLegaleData = useSetRecoilState(legaleState);
const setCertifications = useSetRecoilState(certificationState);
const setAdditionalCertifications = useSetRecoilState(additionalCertificationState);
const setSocCertifications = useSetRecoilState(socialCertificationState);
const setAdditionalSocCertifications = useSetRecoilState(additionalSocialCertificationState);

const handleNext = () => {
  setStep((prevStep) => prevStep + 1);
};

const handlePrevious = () => {
  setStep((prevStep) => prevStep - 1);
};

  const cardStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh', 
    backgroundColor: '#EF6526'
  };

  const fileInputContainer =  {
    display: 'flex',
    alignItems: 'center',
  };
  const fileInput =  {
    display: 'none',
  };

  const handleGetPratica = async () => {
    try {
      const response = await fetch(`https://oryenta-wrapper.dev.madstudio.it/api/v1/get-pratica?codice_pratica=${codicePratica}`, {
        method: 'GET',
        headers: {
          // Inserisci qui eventuali headers richiesti, come Authorization se necessario
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
          throw new Error('Errore nella richiesta');
        }
        
        const data = await response.json();
        setFormData(data?.content?.datiAzienda)
        setReferenteData(data?.content?.datiReferente)
        setLegaleData(data?.content?.datiLegale)
        setCertifications(data?.content?.certifications)
        setAdditionalCertifications(data?.content?.additionalCertifications)
        setSocCertifications(data?.content?.socCertifications)
        setAdditionalSocCertifications(data?.content?.additionalSocCertifications)
        handleNext()
      console.log(data); // Gestisci la risposta come necessario
    } catch (error) {
      console.error('Si è verificato un errore:', error);
    }
  };

  const sendPost = (acceptedFiles) => {

    if (acceptTerms && acceptPrivacy) {
      setSubmitStatus('loading');
      const data = {
          email:email,
          partita_iva:partitaIva,
          nome_azienda:nomeAzienda,
          content:{
              datiAzienda: datiAzienda,
              datiReferente: datiReferente,
              datiLegale: datiLegale,
              socCertifications: socCertifications,
              additionalCertifications,
              additionalSocCertifications,
              certifications: certifications,
          }
      }

          // Perform POST request with formData
      // Example:
      fetch('https://oryenta-wrapper.dev.madstudio.it/api/v1/new-pratica', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
      })
      .then(response => {
        if (response.ok) {
            return response.json();
        } else {
            throw new Error('Qualcosa è andato storto con la tua richiesta.');
        }
      })
      .then(data => {
        console.log(data);
        setSubmitStatus('success');
      })
      .catch(error => {
          console.error('Error:', error);
          setErrorMessage(error.message);
          setSubmitStatus('error');
      });
    }
  }


  return (
  <>
    <Box sx={cardStyle}>

      <Card sx={{ minWidth: 275, maxWidth: 1100 }}>
        <CardContent>
        {submitStatus === 'success' ? (
          // Se il form è stato inviato con successo, mostriamo un messaggio di successo
          <>
            <Typography variant="h4" sx={{ color: 'green' }}>
              Form inviato con successo!
            </Typography>
            <br/>
            <Typography variant="h5" sx={{ color: '#4b4b4b' }}>
              Controlla la mail inserita in fase di compilazione (controlla nello spam se non la trovi)
            </Typography>            <br/>            
            <Typography variant="p" sx={{ color: '#4b4b4b' }}>
              Entro 5 giorni lavorativi dall'invio del questionario, riceverai sulla mail i certificati contenenti i rating di sostenibilità Ambientale e Sociale, con la relativa scadenza.
              Riceverai il numero della tua pratica che ti consentirà di modificare ed aggiornare i tuoi certificati, senza dover compilare nuovamente il questionario, ottenenendo un nuovo ranking.
              Info e supporto: rating.esg@palmu.it
            </Typography>
          </>
        ) : submitStatus === 'loading' ? (
          // Mostra un messaggio di caricamento
          <Typography variant="body1" sx={{ color: 'blue' }}>
            Invio in corso...
          </Typography>
        ) : submitStatus === 'error' ? (
          // Mostra un messaggio di errore
          <Typography variant="body1" sx={{ color: 'red' }}>
            Errore nell'invio: {errorMessage}
          </Typography>
        ) : (
            <>

              <Typography variant="h5" component="h2" gutterBottom  sx={{ marginBottom: 6 }}>
                  Calcolo Rating Sostenibilità
              </Typography>
              <Stepper activeStep={step} alternativeLabel sx={{ marginBottom: 6 }}>
              {steps.map((label) => (
                  <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                  </Step>
              ))}
              </Stepper>
              
          {step < 0 && (
            <>
                <Grid container spacing={3} style={{padding: '24px'}} alignItems="center">
                  <Grid item xs={4}>
                    Recupera pratica esistente
                  </Grid>
                  <Grid item xs={5}>
                      <TextField  fullWidth label="Recupera pratica"
                      variant="outlined"
                      value={codicePratica}
                      onChange={(e) => setCodicePratica(e.target.value)}
                      />
                  </Grid>
                  <Grid item xs={3}>
                      <Button variant="contained" color="primary"
                          onClick={handleGetPratica}
                      >
                          Recupera
                      </Button>
                  </Grid>
                </Grid>
                <Grid container spacing={3} style={{padding: '24px'}} alignItems="center">
                  <Grid item xs={4}>
                    Recupera pratica esistente
                  </Grid>
                  <Grid item xs={8}>
                    <Button variant="contained"  fullWidth={true}  onClick={handleNext}>
                      Crea pratica
                    </Button>
                  </Grid>
                </Grid>
            </>
            )}


              



              <Box component="form" noValidate autoComplete="off">
                <Grid container spacing={2}>
                  {step === 0 && (
                    <>
                      <Step0 />
                    </>
                  )}

                  {step === 1 && (
                    <>
                      <Step1 />
                    </>
                  )}

                  {step === 2 && (
                    <>
                      <StepLegale />  
                    </>
                  )}

                  {step === 3 && (
                    <>
                      <Step2 />  
                    </>
                    )}

                  
                  {step === 4 && (
                    <>
                      <Step3 />  
                    </>
                    )}

                  {step === 5 && (
                    <>
                      <Step4 />  
                    </>
                  )}

                  {step === 6 && (
                    <>
                      <Step5 />  
                    </>
                  )}

                </Grid>
                
                {step > 6 && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end', p: 2 }}>
                    <Box sx={{ alignSelf: 'stretch' }}>
                      {step === steps.length - 1 && (
                        // Sei all'ultimo step, mostra i checkbox di accettazione
                        <Box>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={acceptTerms}
                                onChange={(e) => setAcceptTerms(e.target.checked)}
                              />
                            }
                            label="Accetto i termini e le condizioni"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={acceptPrivacy}
                                onChange={(e) => setAcceptPrivacy(e.target.checked)}
                              />
                            }
                            label="Accetto la politica sulla privacy"
                          />
                        </Box>
                      )}
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'end', alignSelf: 'stretch' }}>
                      <Button variant="text" onClick={handlePrevious}>
                        Indietro
                      </Button>
                      {step < steps.length - 1 ? (
                        <Button variant="contained" onClick={handleNext}>
                          Continua
                        </Button>
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={sendPost}
                            disabled={!acceptTerms || !acceptPrivacy} // Disabilita se uno dei due non è vero
                          >
                            Invia
                          </Button>
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
            </>
          )}
        </CardContent>
      </Card>
    </Box>
    </>
  );
}

export default FormComponent;
