// state/formState.js
import { atom } from 'recoil';

export const referentiState = atom({
  key: 'step1', // identificatore unico
  default: {
    nomeReferente: '',
    cognomeReferente: '',
    emailReferente: '',
    prefissoReferente: '',
    telefonoReferente: '',
    ruoloReferente: '',
  },
});
