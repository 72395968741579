import React, { useState } from 'react';
import { FormControl, FormControlLabel,Checkbox, InputLabel, Select, MenuItem } from '@mui/material';
import { Typography, TextField, Button, Box, Grid, Card, CardContent, Stepper, Step, StepLabel } from '@mui/material';
import { FormHelperText } from '@mui/material';
import * as yup from 'yup';

import { useRecoilState } from 'recoil';
import { useRecoilValue } from 'recoil';
import { useSetRecoilState } from 'recoil';
import { pageState } from './state/pageState';
import { referentiState } from './state/step1';




const Step1 = () => {
    const [page, setPage] = useRecoilState(pageState);

    // const [nomeAzienda, setNomeAzienda] = useState('');
    // const [nazioneSelezionata, setNazioneSelezionata] = useState('');
    // const [partitaIva, setPartitaIva] = useState('');
    // const [settoreSelezionato, setSettoreSelezionato] = useState('');
    // const [customSettore, setCustomSettore] = useState('');
    // const [email, setEmail] = useState('');
    // const [sedeLegale, setSedeLegale] = useState('');
    // const [citta, setCitta] = useState('');
    // const [provincia, setProvincia] = useState('');
    const [errors, setErrors] = useState({});

    const formData = useRecoilValue(referentiState);

    const {
        nomeReferente,
        cognomeReferente,
        emailReferente,
        prefissoReferente,
        telefonoReferente,
        ruoloReferente,
    } = formData;

    const setFormData = useSetRecoilState(referentiState);


    const handlePrevious = () => {
        setPage((prevStep) => prevStep - 1);
      };

    const goToNextPage = () => {
        setPage(page + 1); // Cambia lo step attuale
      };

      const handleChange = (field, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [field]: value,
        }));
    };
    const validateField = async (fieldName, fieldValue) => {
        // Definizione dello schema di validazione per l'intero form
        const schema = getValidationSchema();
    
        try {
            // Validazione del campo specifico
            await schema.validateAt(fieldName, { [fieldName]: fieldValue });
            // Se la validazione ha successo, rimuovi gli errori per questo campo
            setErrors(errors => ({ ...errors, [fieldName]: '' }));
        } catch (err) {
            // Se la validazione fallisce, aggiorna lo stato degli errori con il messaggio di errore
            setErrors(errors => ({ ...errors, [fieldName]: err.message }));
        }
    };
    

    const getValidationSchema = () => {
        let schema = {
            nomeReferente: yup.string().required('Campo obbligatorio'),
            cognomeReferente: yup.string().required('Campo obbligatorio'),
            emailReferente: yup.string().email('Email non valida').required('Campo obbligatorio'),
            prefissoReferente: yup.string().required('Campo obbligatorio').max(4, 'Il prefisso non può superare i 4 caratteri'),
            telefonoReferente: yup.string().required('Campo obbligatorio'),
            ruoloReferente: yup.string().required('Campo obbligatorio')
        };

        return yup.object().shape(schema);
    };

    const handleSubmit = () => {
        validateForm()
        .then(() => {
            setFormData({
                nomeReferente,
                cognomeReferente,
                emailReferente,
                prefissoReferente,
                telefonoReferente,
                ruoloReferente,
              });

            goToNextPage();
        })
        .catch((err) => {
            // Gli errori di validazione sono gestiti dentro validateForm
            console.error('Errore durante la validazione:', err);
        });
    };
    
    const validateForm = () => {
        console.log(formData);

        const validationSchema = getValidationSchema();
    
        return validationSchema.validate({
            nomeReferente,
            cognomeReferente,
            emailReferente,
            prefissoReferente,
            telefonoReferente,
            ruoloReferente,
        }, { abortEarly: false })
        .then(() => {
            console.log('Form submitted successfully!');
            setErrors({}); // Reset degli errori
        })
        .catch((err) => {
            if (err && err.inner) {
                const newErrors = err.inner.reduce((acc, error) => {
                    acc[error.path] = error.message;
                    return acc;
                }, {});
                setErrors(newErrors);
                throw new Error('Validation failed'); // Questo assicura che la catena di promise sia interrotta
            } else {
                console.error('Errore di validazione non previsto:', err);
                setErrors({ global: 'Errore di validazione non gestito.' });
                throw err; // Propaga l'errore per gestirlo nel catch di handleSubmit
            }
        });
    }
        
    return (
        <>

<Grid item xs={12} sm={6}>
                        <TextField 
                            required 
                            fullWidth 
                            value={nomeReferente}
                            onChange={(e) => handleChange('nomeReferente', e.target.value)}
                            onBlur={() => validateField('nomeReferente', nomeReferente)}
                            error={!!errors.nomeReferente}
                            helperText={errors.nomeReferente}
                            label="Nome" 
                            variant="outlined"  
                        />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <TextField 
                            required 
                            fullWidth
                            value={cognomeReferente}
                            onChange={(e) => handleChange('cognomeReferente', e.target.value)}
                            onBlur={() => validateField('cognomeReferente', cognomeReferente)}
                            error={!!errors.cognomeReferente}
                            helperText={errors.cognomeReferente} 
                            label="Cognome" 
                        />
                        </Grid>
                        <Grid item xs={12}>
                        <TextField 
                            required 
                            fullWidth 
                            value={emailReferente}
                            onChange={(e) => handleChange('emailReferente', e.target.value)}
                            onBlur={() => validateField('emailReferente', emailReferente)}
                            error={!!errors.emailReferente}
                            helperText={errors.emailReferente}
                            label="Email" 
                            variant="outlined" 
                        />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <TextField 
                            fullWidth 
                            required
                            label="Prefisso (es. +39)" 
                            value={prefissoReferente}
                            onChange={(e) => handleChange('prefissoReferente', e.target.value)}
                            onBlur={() => validateField('prefissoReferente', prefissoReferente)}
                            error={!!errors.prefissoReferente}
                            helperText={errors.prefissoReferente}
                            variant="outlined" 
                            inputProps={{
                                maxLength: 4
                            }}
                            //defaultValue="+39" 
                        />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <TextField 
                            fullWidth 
                            label="Numero di Telefono" 
                            value={telefonoReferente}
                            onChange={(e) => handleChange('telefonoReferente', e.target.value)}
                            onBlur={() => validateField('telefonoReferente', telefonoReferente)}
                            error={!!errors.telefonoReferente}
                            helperText={errors.telefonoReferente}
                            variant="outlined" 
                        />
                        </Grid>
                        <Grid item xs={12}>
                        <TextField 
                            fullWidth 
                            label="Ruolo" 
                            variant="outlined" 
                            value={ruoloReferente}
                            onChange={(e) => handleChange('ruoloReferente', e.target.value)}
                            onBlur={() => validateField('ruoloReferente', ruoloReferente)}
                            error={!!errors.ruoloReferente}
                            helperText={errors.ruoloReferente}
                        />
                        </Grid>

                        <Grid item xs={12}>
                        <Box sx={{ display: 'flex', justifyContent: 'end', p: 2 }}>
                        <Button variant="text" onClick={handlePrevious}>
                    Indietro
                    </Button>
                            <Button variant="contained" onClick={handleSubmit}>
                            Continua
                            </Button>
                    </Box>
                    </Grid>
        </>
    );
};

export default Step1;
