import './App.css';
import FormComponent from './FormComponent'; // Assicurati che il percorso sia corretto


function App() {
  return (
    <div className="App">
      <FormComponent />
    </div>
  );
}

export default App;
