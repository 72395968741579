// state/formState.js
import { atom } from 'recoil';

export const legaleState = atom({
  key: 'legaleState', // identificatore unico
  default: {
    nomeReferente: '',
    cognomeReferente: '',
    emailReferente: '',
    prefissoReferente: '',
    telefonoReferente: '',
    isReferenteLegaleSame: false,
  },
});
