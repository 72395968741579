import React, { useState } from 'react';
import { FormControl, FormControlLabel, Checkbox, InputLabel, Select, MenuItem, Typography, TextField, Button, Box, Grid, Card, CardContent, Stepper, Step, StepLabel, FormHelperText } from '@mui/material';
import * as yup from 'yup';
import { pageState } from './state/pageState';
import { useRecoilState } from 'recoil';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { additionalSocialCertificationState } from './state/step4';

const Step3 = () => {
    const [page, setPage] = useRecoilState(pageState); // Assicurati che `pageState` sia definito correttamente nel tuo stato globale
    const [errors, setErrors] = useState({});
    const [additionalCertifications, setAdditionalCertifications] = useRecoilState(additionalSocialCertificationState);

    const handlePrevious = () => {
        setPage((prevStep) => prevStep - 1);
    };

    const goToNextPage = () => {
        setPage(page + 1);
    };

    const handleAddCertification = () => {
        setAdditionalCertifications([
            ...additionalCertifications,
            { name: '', file: null }
        ]);
    };

    const handleCertificationNameChange = (index, newName) => {
        const newCertifications = additionalCertifications.map((certification, i) => {
            if (i === index) {
                return { ...certification, name: newName };
            }
            return certification;
        });

        setAdditionalCertifications(newCertifications);
    };

    const handleCertificationFileChange = (index, file) => {
        const newCertifications = additionalCertifications.map((certification, i) => {
            if (i === index) {
                return { ...certification, file: file };
            }
            return certification;
        });

        setAdditionalCertifications(newCertifications);
    };

    const handleRemoveCertification = (index) => {
        const newCertifications = additionalCertifications.filter((_, i) => i !== index);
        setAdditionalCertifications(newCertifications);
    };

    // Assicurati che questa funzione sia collegata e funzioni correttamente come previsto
    const validateField = async (fieldName, fieldValue) => {
        // Implementazione della validazione qui
    };

    // Metodo per ottenere lo schema di validazione, assicurati che venga utilizzato correttamente
    const getValidationSchema = () => {
        // Implementazione dello schema di validazione qui
    };

    const handleSubmit = () => {
        goToNextPage();
    };

    return (
        <>
            {additionalCertifications.map((certification, index) => (
                <Grid container spacing={2} key={index} sx={{ padding: 2 }}>
                    <Grid item xs={6}>
                        <TextField
                            label="Nome certificazione"
                            value={certification.name}
                            onChange={(e) => handleCertificationNameChange(index, e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <input
                            accept=".pdf,.doc,.docx,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            style={{ display: 'none' }}
                            id={`file-upload-${index}`}
                            type="file"
                            onChange={(e) => handleCertificationFileChange(index, e.target.files[0])}
                        />
                        <label htmlFor={`file-upload-${index}`}>
                            <Button variant="outlined" component="span">
                                Carica File
                            </Button>
                        </label>
                    </Grid>
                    <Grid item xs={1}>
                        <Button variant="outlined" color="error" onClick={() => handleRemoveCertification(index)}>
                            <DeleteOutlineIcon />
                        </Button>
                    </Grid>
                    {certification.file && (
                        <Grid item xs={12}>
                            <Typography variant="body2">
                                {certification.file.name}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            ))}
            <Grid container justifyContent="center">
                <Button variant="contained" onClick={handleAddCertification}>
                    Aggiungi Certificazione
                </Button>
            </Grid>
            <Grid item xs={12}>
                        <Box sx={{ display: 'flex', justifyContent: 'end', p: 2 }}>
                        <Button variant="text" onClick={handlePrevious}>
                    Indietro
                    </Button>
                            <Button variant="contained" onClick={handleSubmit}>
                            Continua
                            </Button>
                    </Box>
                    </Grid>
        </>
    );
};

export default Step3;
