// state/formState.js
import { atom } from 'recoil';

export const socialCertificationState = atom({
  key: 'step4', // identificatore unico
  default: {
    "ISO 28000 Requisiti per i Sistemi di Gestione della Sicurezza (Security) nelle catene di fornitura": { checked: false, file: null, expiryDate: null },
    "ISO 37101 Sviluppo sostenibile nelle comunità - Sistema di gestione per lo sviluppo sostenibile - Requisiti con indicazioni per l'uso": { checked: false, file: null, expiryDate: null },
    "UNI CEI ISO/IEC 27001 Tecnologia delle informazioni - Tecniche di sicurezza - Sistemi di gestione della sicurezza delle informazioni - Requisiti": { checked: false, file: null, expiryDate: null },
    "BS OHSAS 18001 Sistema di Gestione della Salute e della Sicurezza sul Lavoro - Requisiti ISO 45001Sistemi di Gestione della Salute e Sicurezza sul Lavoro - Requisiti": { checked: false, file: null, expiryDate: null },
    "UNI ISO 37001 Sistemi di gestione per la prevenzione della corruzione": { checked: false, file: null, expiryDate: null },
    "UNI ISO 39001 Sistemi di gestione della sicurezza del traffico stradale – Requisiti e guida all’utilizzo": { checked: false, file: null, expiryDate: null },
    "Rating di legalità ***": { checked: false, file: null, expiryDate: null }, // Programme for the Endorsement of Forest Certification
    "Rating di legalità **": { checked: false, file: null, expiryDate: null }, // Content Claim Standard
    "Rating di legalità *": { checked: false, file: null, expiryDate: null },
    "B-Corp": { checked: false, file: null, expiryDate: null }, // Global Organic Textile Standard
    "White list contro le infiltrazioni mafiose": { checked: false, file: null, expiryDate: null },
    "Società Benefit": { checked: false, file: null, expiryDate: null }, // Global Recycled Standard
    "Anagrafe Antimafia Esecutori Ricostruzione Post Sisma Centro Italia": { checked: false, file: null, expiryDate: null }, // Nativa Precious Fiber
    "OUNI 10617 Impianti a rischio di incidente rilevante- Sistema di gestione della sicurezza Requisiti essenziali": { checked: false, file: null, expiryDate: null }, // Organic Content Standar
  },
});

export const additionalSocialCertificationState = atom({
  key: 'additionalSocialCertification', // identificatore unico
  default: []
});

