// state/formState.js
import { atom } from 'recoil';

export const formState = atom({
  key: 'step0', // identificatore unico
  default: {
    nomeAzienda: '',
    nazioneSelezionata: '',
    partitaIva: '',
    settoreSelezionato: '',
    codiceAteco:'',
    pec: '',
    customSettore: '',
    numeroCivico:'',
    email: '',
    sedeLegale: '',
    citta: '',
    provincia: '',
  },
});
