import React, { useState } from 'react';
import { FormControl, FormControlLabel,Checkbox, InputLabel, Select, MenuItem } from '@mui/material';
import { Typography, TextField, Button, Box, Grid, Card, CardContent, Stepper, Step, StepLabel } from '@mui/material';
import { FormHelperText } from '@mui/material';
import * as yup from 'yup';

import { useRecoilState } from 'recoil';
import { useRecoilValue } from 'recoil';
import { useSetRecoilState } from 'recoil';

import { pageState } from './state/pageState';
import { formState } from './state/step0';
import { referentiState } from './state/step1';
import { certificationState } from './state/step2';
import { additionalCertificationState } from './state/step2';
import { socialCertificationState } from './state/step4';
import { additionalSocialCertificationState } from './state/step4';


const Step0 = () => {
    const [page, setPage] = useRecoilState(pageState);
    const [errors, setErrors] = useState({});
    const [codicePratica, setCodicePratica] = useState('');

    const formData = useRecoilValue(formState);

    const {
        nomeAzienda,
        nazioneSelezionata,
        partitaIva,
        pec,
        codiceAteco,
        settoreLegale,
        settoreSelezionato,
        customSettore,
        numeroCivico,
        email,
        sedeLegale,
        citta,
        provincia,
        visuraCamerale,
    } = formData;


    const [datiAzienda] = useRecoilState(formState);
    const setFormData = useSetRecoilState(formState);

    const goToNextPage = () => {
        setPage(page + 1); // Cambia lo step attuale
      };

    const handleChange = (field, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [field]: value,
        }));
    };
    const validateField = async (fieldName, fieldValue) => {
        // Definizione dello schema di validazione per l'intero form
        const schema = getValidationSchema();
    
        try {
            // Validazione del campo specifico
            await schema.validateAt(fieldName, { [fieldName]: fieldValue });
            // Se la validazione ha successo, rimuovi gli errori per questo campo
            setErrors(errors => ({ ...errors, [fieldName]: '' }));
        } catch (err) {
            // Se la validazione fallisce, aggiorna lo stato degli errori con il messaggio di errore
            setErrors(errors => ({ ...errors, [fieldName]: err.message }));
        }
    };
    

    const getValidationSchema = () => {
        let schema = {
            nomeAzienda: yup.string().required('Campo obbligatorio'),
            nazioneSelezionata: yup.string().required('Campo obbligatorio'),
            partitaIva: yup.string().required('Campo obbligatorio').max(11, 'La Partita IVA non può superare gli 11 caratteri'),
            settoreSelezionato: yup.string().required('Campo obbligatorio'),
            email: yup.string().email('Email non valida').required('Campo obbligatorio'),
            sedeLegale: yup.string().required('Campo obbligatorio'),
            numeroCivico: yup.string().nullable(),
            citta: yup.string().required('Campo obbligatorio'),
            provincia: yup.string().required('Campo obbligatorio'),
        };

        if (nazioneSelezionata === 'Italy') {
            schema = {
                ...schema,
                settoreLegale:yup.string().required('Campo obbligatorio'),
                pec: yup.string().required('Campo obbligatorio'),
                codiceAteco :yup.string().required('Campo obbligatorio'),
                visuraCamerale :yup.string().required('Campo obbligatorio'),
            };
        }

        if (settoreSelezionato === 'Other') {
            schema = {
                ...schema,
                customSettore: yup.string().required('Campo obbligatorio'),
            };
        }

        return yup.object().shape(schema);
    };

    const handleSubmit = () => {
        validateForm()
        .then(() => {
            setFormData({
                nomeAzienda,
                nazioneSelezionata,
                partitaIva,
                settoreLegale,
                settoreSelezionato,
                pec,
                codiceAteco,
                visuraCamerale,
                customSettore,
                email,
                sedeLegale,
                numeroCivico,
                citta,
                provincia,
              });

            goToNextPage();
        })
        .catch((err) => {
            // Gli errori di validazione sono gestiti dentro validateForm
            console.error('Errore durante la validazione:', err);
        });
    };

    const formatCodiceAteco = (value) => {
        // Rimuove caratteri non numerici
        const numericValue = value.replace(/\D/g, '');
        
        // Se ha almeno 4 cifre, formatta come "__.__"
        if (numericValue.length >= 4) {
            return `${numericValue.slice(0, 2)}.${numericValue.slice(2, 4)}`;
        }
        
        // Altrimenti, restituisce il valore numerico senza modifiche
        return numericValue;
    };
    
    
    const validateForm = () => {
        console.log(formData);

        const validationSchema = getValidationSchema();
    
        return validationSchema.validate({
            nomeAzienda,
            nazioneSelezionata,
            partitaIva,
            settoreSelezionato,
            pec,
            settoreLegale,
            codiceAteco,
            visuraCamerale,
            customSettore, // Includilo sempre nella validazione, ma sarà obbligatorio solo se necessario
            email,
            sedeLegale,
            citta,
            provincia,
        }, { abortEarly: false })
        .then(() => {
            console.log('Form submitted successfully!');
            setErrors({}); // Reset degli errori
        })
        .catch((err) => {
            if (err && err.inner) {
                const newErrors = err.inner.reduce((acc, error) => {
                    acc[error.path] = error.message;
                    return acc;
                }, {});
                setErrors(newErrors);
                throw new Error('Validation failed'); // Questo assicura che la catena di promise sia interrotta
            } else {
                console.error('Errore di validazione non previsto:', err);
                setErrors({ global: 'Errore di validazione non gestito.' });
                throw err; // Propaga l'errore per gestirlo nel catch di handleSubmit
            }
        });
    }
        
    return (
        <>

            <Grid item xs={4}>
                <TextField
                    required
                    fullWidth
                    label="Nome Azienda"
                    variant="outlined"
                    value={nomeAzienda}
                    onChange={(e) => handleChange('nomeAzienda', e.target.value)}
                    onBlur={() => validateField('nomeAzienda', nomeAzienda)}
                    error={!!errors.nomeAzienda}
                    helperText={errors.nomeAzienda}
                />
            </Grid>

            <Grid item xs={12} sm={4}>
                <FormControl fullWidth required error={!!errors.nazioneSelezionata} onBlur={() => validateField('nazioneSelezionata', nazioneSelezionata)}>
                    <InputLabel id="nazione-label">Nazione</InputLabel>
                    <Select
                        required
                        labelId="nazione-select-label"
                        id="nazione-select"
                        value={nazioneSelezionata}
                        label="Nazione"
                        onChange={(e) => handleChange('nazioneSelezionata', e.target.value)}
                        >
                        <MenuItem value=""><em>Seleziona Nazione</em></MenuItem>
                        <MenuItem value="Italy">Italia</MenuItem>
                        <MenuItem value="Albania">Albania</MenuItem>
                        <MenuItem value="Andorra">Andorra</MenuItem>
                        <MenuItem value="Austria">Austria</MenuItem>
                        <MenuItem value="Belarus">Bielorussi</MenuItem>
                        <MenuItem value="Belgium">Belgio</MenuItem>
                        <MenuItem value="Bosnia and Herzegovina">Bosnia Erzegovina</MenuItem>
                        <MenuItem value="Bulgaria">Bulgaria</MenuItem>
                        <MenuItem value="Croatia">Croazia</MenuItem>
                        <MenuItem value="Cyprus">Cipro</MenuItem>
                        <MenuItem value="Czech Republic">Repubblica Ceca</MenuItem>
                        <MenuItem value="Denmark">Danimarca</MenuItem>
                        <MenuItem value="Estonia">Estonia</MenuItem>
                        <MenuItem value="Finland">Finlandia</MenuItem>
                        <MenuItem value="France">Francia</MenuItem>
                        <MenuItem value="Germany">Germania</MenuItem>
                        <MenuItem value="Greece">Grecia</MenuItem>
                        <MenuItem value="Hungary">Ungheria</MenuItem>
                        <MenuItem value="Iceland">Islanda</MenuItem>
                        <MenuItem value="Ireland">Irlanda</MenuItem>
                        <MenuItem value="Latvia">Lettonia</MenuItem>
                        <MenuItem value="Liechtenstein">Liechtenstein</MenuItem>
                        <MenuItem value="Lithuania">Lituania</MenuItem>
                        <MenuItem value="Luxembourg">Lussemburgo</MenuItem>
                        <MenuItem value="Macedonia">Macedonia</MenuItem>
                        <MenuItem value="Malta">Malta</MenuItem>
                        <MenuItem value="Moldova">Moldovia</MenuItem>
                        <MenuItem value="Montenegro">Montenegro</MenuItem>
                        <MenuItem value="Munich">Monaco princip.</MenuItem> {/* Munich è una città, non una nazione. Potresti voler correggere o rimuovere questa opzione. */}
                        <MenuItem value="Netherlands">Olanda</MenuItem>
                        <MenuItem value="Norway">Norvegia</MenuItem>
                        <MenuItem value="Poland">Polonia</MenuItem>
                        <MenuItem value="Portugal">Portogallo</MenuItem>
                        <MenuItem value="Romania">Romania</MenuItem>
                        <MenuItem value="Russia">Russia</MenuItem>
                        <MenuItem value="San Marino">San Marino</MenuItem>
                        <MenuItem value="Serbia">Serbia</MenuItem>
                        <MenuItem value="Slovakia">Slovacchia</MenuItem>
                        <MenuItem value="Slovenia">Slovenia</MenuItem>
                        <MenuItem value="Spain">Spagna</MenuItem>
                        <MenuItem value="Sweden">Svezia</MenuItem>
                        <MenuItem value="Swiss">Svizzera</MenuItem> {/* "Swiss" è un aggettivo. Il nome della nazione è "Switzerland" */}
                        <MenuItem value="Ukraine">Ucraina</MenuItem>
                        <MenuItem value="United Kingdom">Regno Unito</MenuItem>
                        <MenuItem value="Vatican City">Città del Vaticano</MenuItem>
                    </Select>
                    {errors.nazioneSelezionata && <FormHelperText>{errors.nazioneSelezionata}</FormHelperText>}
                </FormControl>
            </Grid>

            {nazioneSelezionata === 'Italy' && (
                <>
                    <Grid item xs={12} sm={4}>
                    <FormControl fullWidth required error={!!errors.settoreLegale} onBlur={() => validateField('settoreLegale', settoreLegale)}>
                        <InputLabel id="settore-label">Settore Legale</InputLabel>
                                <Select
                                required
                                labelId="settore-select-label"
                                id="settore-select"
                                value={settoreLegale}
                                label="Settore Legale"
                                onChange={(e) => handleChange('settoreLegale', e.target.value)}
                                >
                                    <MenuItem value=""><em>Selziona settore legale</em></MenuItem>
                                    <MenuItem value="S.S.">S.S.</MenuItem>
                                    <MenuItem value="S.N.C">S.N.C</MenuItem>
                                    <MenuItem value="S.A.S">S.A.S</MenuItem>
                                    <MenuItem value="S.R.L">S.R.L</MenuItem>
                                    <MenuItem value="S.P.A">S.P.A</MenuItem>
                                    <MenuItem value="S.A.P.A">S.A.P.A</MenuItem>
                                </Select>
                                {errors.settoreLegale && <FormHelperText>{errors.settoreLegale}</FormHelperText>}
                </FormControl>

                    </Grid>
                </>    
            )} 


            <Grid item xs={12} sm={4}>
                <TextField required value={partitaIva} fullWidth label="P.Iva" variant="outlined" onChange={(e) => handleChange('partitaIva', e.target.value)}       
                onBlur={() => validateField('partitaIva', partitaIva)}                     
                error={!!errors.partitaIva}
                inputProps={{
                    maxLength: 11
                }}
                helperText={errors.partitaIva}/>
            </Grid>

            {nazioneSelezionata === 'Italy' && (
                <>

                    <Grid item xs={12} sm={4}>
                    <TextField 
                    required 
                    value={codiceAteco} 
                    fullWidth 
                    label="Codice Ateco (_ _ . _ _)" 
                    variant="outlined" 
                    onChange={(e) => handleChange('codiceAteco', formatCodiceAteco(e.target.value))}
                    onBlur={() => validateField('codiceAteco', codiceAteco)}                     
                    error={!!errors.codiceAteco}
                    helperText={errors.codiceAteco}
/>

                    </Grid>  

                    <Grid item xs={12} sm={4}>
                        <TextField required value={pec} fullWidth label="Pec" variant="outlined" onChange={(e) => handleChange('pec', e.target.value)}       
                        onBlur={() => validateField('pec', pec)}                     
                        error={!!errors.pec}
                        helperText={errors.pec}/>
                    </Grid> 
                </>    
            )} 

            <Grid item xs={6}>
                <FormControl fullWidth required error={!!errors.settoreSelezionato}   onBlur={() => validateField('settoreSelezionato', settoreSelezionato)} >
                    <InputLabel id="nazione-label">Settore operativo</InputLabel>

                    <Select
                        labelId="settore-select-label"
                        id="settore-select"
                        value={settoreSelezionato}
                        label="Settore operativo"
                        onChange={(e) => handleChange('settoreSelezionato', e.target.value)}
                    >
                        <MenuItem value=""><em>Selezione Settore Operativo</em></MenuItem>
                        <MenuItem value="Agricolture">Agricolo</MenuItem>
                        <MenuItem value="Buildings">Costruzioni</MenuItem>
                        <MenuItem value="Energy and Utility">Energia</MenuItem>
                        <MenuItem value="Industry">Industriale</MenuItem>
                        <MenuItem value="Services">Servizi</MenuItem>
                        <MenuItem value="Other">Altro</MenuItem>
                    </Select>
                    {errors.settoreSelezionato && <FormHelperText>{errors.settoreSelezionato}</FormHelperText>}
                </FormControl>
        
                <Typography variant="body2" style={{ marginTop: '8px' }}>
                </Typography>
            </Grid>
            
            {settoreSelezionato === 'Other' && (
                <Grid item xs={12}>
                    <TextField
                        required
                        fullWidth
                        label="Specificare il settore"
                        variant="outlined"
                        value={customSettore}
                        onChange={(e) => handleChange('customSettore', e.target.value)}
                        error={!!errors.customSettore}
                        helperText={errors.customSettore || ''}
                    />
                </Grid>
            )} 

<Grid item xs={6}>
                <TextField 
                error={!!errors.email}
                helperText={errors.email} required 
                value={email} fullWidth label="Email aziendale" 
                type="email" variant="outlined"
                onChange={(e) => handleChange('email', e.target.value)}
                onBlur={() => validateField('email', email)} />
            </Grid>


<Grid item xs={12} sm={4}>
                <TextField 
                value={sedeLegale}
                error={!!errors.sedeLegale}
                helperText={errors.sedeLegale} required fullWidth label="Sede legale (via/viale/piazza)"
                onBlur={() => validateField('sedeLegale', sedeLegale)}
                    variant="outlined"onChange={(e) => {
                    handleChange('sedeLegale', e.target.value)
                    }}
                    />
            </Grid>
            <Grid item xs={12} sm={2}>
                <TextField 
                value={numeroCivico}
                error={!!errors.numeroCivico}
                helperText={errors.numeroCivico} fullWidth label="Numero Civico"
                onBlur={() => validateField('numeroCivico', numeroCivico)}
                    variant="outlined"onChange={(e) => {
                    handleChange('numeroCivico', e.target.value)
                    }}
                    />
            </Grid>

            <Grid item xs={12} sm={4}>
                <TextField error={!!errors.citta}
                            value={citta}
                helperText={errors.citta} required fullWidth 
                onBlur={() => validateField('citta', citta)}
                label="Città" variant="outlined" onChange={(e) => handleChange('citta', e.target.value)}/>
            </Grid>

            <Grid item xs={12} sm={2}>
                <TextField error={!!errors.provincia}
                       inputProps={{
                        maxLength: 2
                    }}
                        value={provincia}
                onBlur={() => validateField('provincia', provincia)}
                helperText={errors.provincia} required fullWidth label="Provincia" variant="outlined" onChange={(e) => handleChange('provincia', e.target.value)} />
            </Grid>
            {nazioneSelezionata === 'Italy' && (
                <>


            <Grid item xs={12} sm={12}>
                <TextField error={!!errors.visuraCamerale}
                       inputProps={{
                        maxLength: 20
                    }}
                        value={visuraCamerale}
                onBlur={() => validateField('Visura Camerale', visuraCamerale)}
                helperText={errors.visuraCamerale} required fullWidth label="Visura Camerale" variant="outlined" onChange={(e) => handleChange('visuraCamerale', e.target.value)} />
            </Grid>
            </>
                     )} 

            <Grid item xs={12}>
                <Box sx={{ display: 'flex', justifyContent: 'end', p: 2 }}>
                    <Button variant="contained" onClick={handleSubmit}>
                    Continua
                    </Button>
                </Box>
            </Grid>
        </>
    );
};

export default Step0;
