import React, { useState, useCallback } from 'react';
import { FormControl, FormControlLabel,Checkbox, InputLabel, Select, MenuItem } from '@mui/material';
import { Typography, TextField, Button, Box, Grid, Card, CardContent, Stepper, Step, StepLabel } from '@mui/material';
import { FormHelperText } from '@mui/material';
import * as yup from 'yup';

import { useRecoilState } from 'recoil';
import { useRecoilValue } from 'recoil';
import { useSetRecoilState } from 'recoil';
import { pageState } from './state/pageState';
import { socialCertificationState } from './state/step4';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';





const Step4 = () => {
    const [page, setPage] = useRecoilState(pageState);
    const [errors, setErrors] = useState({});

    const [certifications, setCertifications] = useRecoilState(socialCertificationState);


    const getTodayString = () => {
        const today = new Date();
        const year = today.getFullYear();
        // Aggiungi 1 perché getMonth() ritorna mesi da 0 (gennaio) a 11 (dicembre)
        const month = (today.getMonth() + 1).toString().padStart(2, '0');
        const day = today.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
      };

    const handlePrevious = () => {
        setPage((prevStep) => prevStep - 1);
      };

    const goToNextPage = () => {
        setPage(page + 1); // Cambia lo step attuale
      };



      const handleSubmit = async () => {
        let isValid = true;
        const errors = {};
      
        // Ciclo attraverso ogni certificazione per controllare se sono richiesti data di scadenza e file
        for (const [certificationName, { checked, expiryDate, file }] of Object.entries(certifications)) {
          if (checked) {
            if (!expiryDate) {
              isValid = false;
              // Aggiungi l'errore specifico della certificazione
              errors[`${certificationName}_expiryDate`] = 'La data di scadenza è obbligatoria';
            }
            if (!file) {
              isValid = false;
              // Aggiungi l'errore specifico della certificazione
              errors[`${certificationName}_file`] = 'Il certificato è obbligatorio';
            }
          }
        }
      
        // Se non valido, imposta gli errori e interrompi la sottomissione
        if (!isValid) {
          setErrors(errors);
          return;
        }
      
        goToNextPage();
        
        
      };
  

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCertifications((prevCerts) => ({
          ...prevCerts,
          [name]: { ...prevCerts[name], checked },
        }));
      };

      const handleDateChange = (name, date) => {
        setCertifications((prevCerts) => ({
          ...prevCerts,
          [name]: { ...prevCerts[name], expiryDate: date },
        }));
      };

      const handleFileChange = useCallback((name, file) => {
        if (file) {
          const reader = new FileReader();
          reader.onloadend = () => {
            // Quando la lettura del file è completa, `reader.result` contiene la stringa Base64
            setCertifications((prevCerts) => ({
              ...prevCerts,
              [name]: { ...prevCerts[name], file, fileBase64: reader.result },
            }));
          };
          reader.readAsDataURL(file); // Inizia la lettura del file e convertilo in Base64
        } else {
          // Gestisce il caso in cui nessun file viene selezionato (ad esempio, se l'utente cancella la selezione del file)
          setCertifications((prevCerts) => ({
            ...prevCerts,
            [name]: { ...prevCerts[name], file: null, fileBase64: '' },
          }));
        }
      }, [setCertifications]); // `setCertifications` is stable since it's provided by Recoil, but include any other dependencies as needed
      

      const onDragOver = useCallback((e) => {
        e.preventDefault(); // Impedisci al browser di eseguire azioni predefinite sull'evento.
      }, []);

      const onDrop = useCallback((e, name) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file) {
          handleFileChange(name, file);
        }
      }, [handleFileChange]); // Assuming handleFileChange is memoized or its dependency is stable
      
      
        
      return (
        <>
          <Grid container spacing={2} sx={{ padding: 2 }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="Tabella delle certificazioni">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Certificazione</TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {Object.entries(certifications).map(([certificationName, certificationDetails]) => (
                    <TableRow
                      key={certificationName}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                      <Checkbox
                          checked={certificationDetails.checked}
                          onChange={(e) => handleCheckboxChange(e, certificationName)}
                          name={certificationName}
                          />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {certificationName.replace(/_/g, ' ')}
                      </TableCell>
                      <TableCell align="right">
                      {certificationDetails.checked && (
                        <TextField
                                label="Data di scadenza" // Aggiungi questa riga per la label
                                type="date"
                                InputProps={{ inputProps: { min: getTodayString() } }}
                                value={certificationDetails.expiryDate || ''}
                                onChange={(e) => handleDateChange(certificationName, e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                sx={{ width: 'fit-content' }}
                                error={!!errors[`${certificationName}_expiryDate`]}
                                helperText={errors[`${certificationName}_expiryDate`] || ''}
                            />
                            )}
                      </TableCell>
                      <TableCell align="right">
                      {certificationDetails.checked && (
                      <Grid item>
                        
                            <label htmlFor={`file-upload-${certificationName}`}
                                  style={{ border: '2px dashed #ccc', padding: '20px', cursor: 'pointer', textAlign: 'center', display: 'block' }}
                                  onDragOver={onDragOver}
                                  onDrop={(e) => onDrop(e, certificationName)}>
                              <input
                                accept=".pdf,.doc,.docx,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                style={{ display: 'none' }}
                                id={`file-upload-${certificationName}`}
                                type="file"
                                onChange={(e) => handleFileChange(certificationName, e.target.files[0])}
                              />
                              Trascina qui il file o clicca per selezionare
                            </label>
                            {certificationDetails.file && (
                              <Typography variant="body2" sx={{ mt: 2, textAlign: 'center' }}>
                                {certificationDetails.file.name}
                              </Typography>
                            )}
                            {/* Mostra errore sotto l'input di file se non è stato caricato un file quando necessario */}
                            {!!errors[`${certificationName}_file`] && (
                              <FormHelperText error>
                                {errors[`${certificationName}_file`]}
                              </FormHelperText>
                            )}
                          </Grid>
                              )}
                          </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

        </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'end', p: 2 }}>
              <Button variant="text" onClick={handlePrevious}>
                Indietro
              </Button>
              <Button variant="contained" onClick={handleSubmit}>
                Continua
              </Button>
            </Box>
          </Grid>              
        </>
    );
};

export default Step4;
